$max-width:940px;

$top-height:60px;
$banner-height: 242px;

$logo-area-width:350px;
$slider-area-width:$max-width - $logo-area-width;

$menu-height:32px;

$background-color: rgb(228, 228, 228);
$primary-font-color: rgb(102,102,102);

// Nav
$nav-menubar-background: rgb(32, 47, 72);
$nav-menubar-color: rgb(255, 255, 255);
$nav-menu-background: rgb(70, 81, 100);
$nav-menu-item-inactive-background: rgb(70, 81, 100);
$nav-menu-item-inactive-color: rgb(184, 174, 148);
$nav-menu-item-hover-background: rgb(114, 125, 144);
$nav-menu-item-inactive-color: rgb(255, 255, 255);

// left nav
$left-nav-background: rgb(234, 234, 234);
$left-nav-link-inactive:rgb(70, 81, 100);
$left-nav-link-active:rgb(177,177,177);

// theme
$main-color:rgb(187, 177, 151);

// footer
$footer-primary-color:rgb(195, 195, 195);
$footer-secondary-color:rgb(165, 164, 164);
$footer-links-inactive:rgb(40, 40, 68);

$order-tracking-border:rgb(40, 40, 68);
$order-tracking-background: rgb(138, 144, 153);
$order-tracking-border-radius: 5px;

$placeholder: rgb(163, 34, 34);

$article-font-size:14px;
$article-title-font-size:18px;
$article-small-font-size:12px;
$footer-link-size:11px;

$table-border-color:#DDD;
$table-radius: 0.5em;