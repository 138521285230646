.homepage-button {

  div {
    display:block;
    height: 89px;
    width:285px;
    background: center center no-repeat;
  }
  
  &.faq {
    div {
      background-image:url(/images/button-FAQs-off.png);
    }
    &:hover > div {
      background-image:url(/images/button-FAQs.png);
    }
  }

  &.get-in-touch {
    div {
      background-image:url(/images/button-get-in-touch-off.png);
    }
    &:hover > div {
      background-image:url(/images/button-get-in-touch.png);
    }
  }

  &.news-archive {
    div {
      background-image:url(/images/button-news-archive-off.png);
    }
    &:hover > div {
      background-image:url(/images/button-news-archive.png);
    }
  }
}