

nav.left-nav {
  ul {
    margin:0 -20px 0 0;
    padding:0;
    li {
      position: relative;
      display: block;
      width:100%;
      list-style:none;
      padding:5px 20px;
      //margin: 0 -20px 0 0;
      a {
        color:$left-nav-link-inactive;
        font-size: 16px;
        font-weight:400;
        text-decoration: none;
      }
      &.active {
        background-color: white;
        a {
          color:$left-nav-link-active;
        }
      }
    }
  }

}

// Medium devices (tablets, 768px and up)
@media (min-width: 1px) { 
  .left-nav-bg {
    background:$left-nav-background;
  }
}
// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
  .left-nav-bg {
    background:white url(/images/left-split-bg.png) top left repeat-y;
  }  
}