#slider {
  overflow: hidden;
  
  .panes {
    .pane {
      position:absolute;
      top:0;
      left:0;

      &:first-child {
        z-index: 10;
      }

      &.hideMe {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s 2s, opacity 2s linear;
      }

      &.showMe {
        visibility: visible;
        opacity: 1;
        transition: opacity 2s linear;
      }
      
    }
  }
}