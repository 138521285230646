$link-size:150px;
$link-color:rgb(217,217,217);

$link-inner-size: $link-size - 50;

.link-cell {

  position:relative;
  margin:0 auto 70px auto;

  width:$link-size;
  height:$link-size;

  > a {
    display:block;
    position:absolute;

    z-index:2;
    width:$link-size;
    height:$link-size;

    border:1px solid $link-color;
    border-radius: 10px;

    background: white center center no-repeat;
    background-size:100px 100px;

    img {
      display:block;
      width:$link-inner-size;
      height:$link-inner-size;
      margin:($link-size - $link-inner-size) / 2;
    }

  }

  &::before {

    display:block;
    position:absolute;
    bottom:5px;

    /* create a new stacking context */
    position: absolute;
    z-index: 1;  /* to be below the parent element */

    width:$link-size - 60;
    left:50%;
    margin-left:-(($link-size - 60) / 2);
    height:20px;

    background:transparent;

    border-radius: 30px;
    content:'';

    box-shadow: 0px 0px 30px rgba(0,0,0,0.5);

  }

  .link-text {

    display:block;
    position:absolute;

    width:$link-size;
    height:30px;

    bottom:-40px;

    a {
      color: $primary-font-color;
      font-size: $article-small-font-size;
    }

  }

}