@mixin menu-glyph {
}

@media (min-width: 1px) { 
  nav.main-nav {
    position:relative;
    height:$menu-height;
    background-color:$nav-menubar-background;

    &.expanded {
      height: auto;

      > ul {
        display: block;
        padding: 40px 0 10px 10px;
        margin-bottom:0;

        li {
          list-style: none;
          padding-left:0;
          font-size: 20px;
          a {
            color: white;
          }

          > ul {
            display: block;
          }
        }
      }

      .hamburger {
        div {
          &:nth-child(1) {
            top: 7px;
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            transform: rotate(-45deg);
          }

          &:last-child {
            display:none;
          }
        }
      }
    }

    ul {
      display: none;
    } 

    &:before { 
      content: 'MENU';
      font-size: 20px;
      color: white;
      position: absolute;
      top: 1px;
      left: 10px;
    }

    .hamburger {
      cursor: pointer;
      position:absolute;
      right: 5px;
      width:32px;
      height: 20px;
      top: 4px;
      div {
        transition: all 0.2s;
        display:block;
        position: relative;
        width: 25px;
        height: 3px;
        margin: 4px;
        background-color: white;
        content: '-';
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  nav.main-nav {
    position:relative;
    overflow: visible;
    .hamburger { display: none; }

    ul {
      height:$menu-height;
      display:block;
      position: relative;
      padding:0;
      margin:0 auto;
      list-style: none;
      text-align: center;
      background-color:$nav-menubar-background;

      > li {
        height:$menu-height;
        display: inline-block;
        margin: 0 10px;
        padding:7px 0;
        font-size:13px;
        overflow: visible;

        &.active::after {
          position:relative;
          display:block;
          width:10px;
          height:10px;
          background-color: $nav-menubar-background;
          content: ' ';
          left:50%;
          margin-top:2px;
          transform: translateX(-50%) rotate(45deg);
          z-index:5;
        }

        a {
          height:$menu-height;
          color:#Fff;
        }

        ul {
          position:absolute;
          z-index:5;
          height: auto;
          display:none;

          li {
            position: relative;
            display: block;
          }
        }

        &:hover {
          ul {
            position: absolute;
            display:block !important;
            height:auto;
            border:1px solid $nav-menubar-background;
            background: $nav-menu-background;
            margin:7px 0 0 0;
            transform: translateX(-50%);
            width:auto;
            z-index:7;

            li {
              display:block !important;
              float:none;
              clear:both;
              margin:0;
              padding:5px 5px 10px 5px;

              a {
                color:#b8ae94;
              }

              &:hover {
                background:#727d90;
                a {
                  color:white;
                }
              }
            }

            &::before {
              position:absolute;
              display:block;
              width:10px;
              height:10px;
              background-color: $nav-menubar-background;
              content: ' ';
              top:-5px;
              left:50%;
              transform: translateX(-50%) rotate(45deg);
              z-index:2;
            }
          }
        }

      }
    }

    .menu-shadow {
      position:absolute;
      top:$menu-height;
      width:100%;
      height:15px;
      background: transparent url(../images/menu-shadow.png) top center no-repeat;
      z-index:1;
    }
  }
}