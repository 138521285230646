.feedback {
  margin:20px 50px;
  display:none;

  p {
    font-size:14px;
    line-height: 30px;
    padding:10px;
    margin: 0;
  }

  &.bad {
    background:rgb(199, 51, 28);
    color:white;
  }

  &.good {
    background:rgb(71, 178, 28);
    color:black;
  }

}