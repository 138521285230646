// Normalize Styles
//@import 'node_modules/normalize.css/normalize';

// Bootstrap
@import 'node_modules/bootstrap/scss/bootstrap.scss';

@import "variables";

@import "text";

@import "common";

html, body {
  font-size:12px;
  font-weight: 300;
  font-family: 'Ubuntu', sans-serif;
  background:url(/images/background-1px.png) top left repeat;
  color:$primary-font-color;
}

.container {
  max-width:$max-width;
  padding:0;
  &.overflow { overflow: visible; }
}

a, a:hover { text-decoration: none; }

.image {
  width:100%;
  margin:0;
  padding:0;
}

.main-background {
  background-color:$main-color;
}

.homepage-image-title {
  padding-bottom:10px;
}

.article-background {
  background:url(/images/article-background-1px.png) top left repeat;
}

ul.social-icons {
  position:absolute;
  top:0;
  left:0;

  margin:0;
  padding:0;
  list-style:none;
  li {
    float:left;
    margin: 10px 5px;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
    a {
      img {
        width:33px;
      }
    }
  }
}

.download-brochure {
  position:absolute;
  top:8px;
  left:220px;
  width:162px;

  a {
    div {
      width:161px;
      height:36px;
      background: url(/images/download-a-brochure-off.png) center center no-repeat;
    }
    &:hover {
      div {
        background: url(/images/download-a-brochure.png) center center no-repeat;
      }
    }
  }

  &.mobile a div {
    background-image: url(/images/download-a-brochure-off-blue.png);
    &:hover { 
      background-image: url(/images/download-a-brochure-off-blue.png); 
    }
  }
}

header {
  display: block;
  position:relative;

  .top {
    margin-top:10px;
    position:relative;
    height:$top-height;

    .search {
      position:absolute;
      top:8px;
      right:0;

      width:320px;
      height:32px;

      form {
        input {
          display:block;
          height:32px;
          float:left;
          background: url(/images/search-box.png) top left no-repeat;
          background-size: 700px 32px;
          border: none;

          &#query {
            width:258px;
            background-position: 0 0;
            text-indent:10px;
          }
          &[type="submit"] {
            width:62px;
            background-position: -638px 0;
            color:#FFF;
          }
        }
      }
    }
  }

  .banner {
    display: block;
    position: relative;

    .logo-contact {

      .logo {
        img {
          display: block;
          position: relative;
          margin:10px auto;
          width:232px;
        }
        div {
          font-size:20px;
          text-align: center;
          color: rgb(179, 22, 25);
        }
      }
      address {
        font-size:12px;
        color:rgb(32, 47, 72);
        text-align: center;
        margin-bottom: 5px;
      }
      .tel {
        font-size:22px;
        color:rgb(32, 47, 72);
        text-align: center;
        a {
          color:inherit;
        }
      }
    }

    .slider {
      position:absolute;
      z-index: 1;
      width: $slider-area-width + 10;
      height:$banner-height;
      top:0;
      right:0;
      overflow: hidden;
    }

  }

}

@import "main-nav";
@import "homepage";

footer {
  .container {
    padding: 30px 0;
    background:$footer-primary-color url(/images/footer-bg.png) bottom left no-repeat;
  }


  .online-order-form-link {
    width:100%;
    clear:both;
    float:none;
    margin-bottom:10px;
    position: relative;
    margin:0 auto;
    a {

      img {

      }

    }

  }

  nav {
    ul{
      width:50%;
      list-style: none;
      margin:0;
      padding:0;
      li {
        a {
          font-size:$footer-link-size;
          color:$footer-links-inactive;
        }
      }
    }
    ul:first-child { float: left; }
    ul:last-child { float:right; }
  }

  .order-tracking {
    width:230px;
    height:auto;
    border-radius: $order-tracking-border-radius;
    border:1px solid $footer-links-inactive;
    background: $order-tracking-background;
    padding:2px;

    form {
      display: block;
      height: 60px;

      ::-webkit-input-placeholder {
        color: $placeholder;
        font-style: italic;
      }

      :-moz-placeholder { /* Firefox 18- */
        color: $placeholder;
        font-style: italic;
      }

      ::-moz-placeholder {  /* Firefox 19+ */
        color: $placeholder;
        font-style: italic;
      }

      :-ms-input-placeholder {
        color: $placeholder;
        font-style: italic;
      }
    }

    .bar {
      background: url(/images/order-tracking-box.png) top left no-repeat;
      background-size: 221px 29px;
      width:221px;
      height:29px;
      margin-bottom:5px;
    }

    input {
      display:block;
      float:left;
      height:29px;
      background: url(/images/order-tracking-box.png) top left no-repeat;
      background-size: 221px 29px;
      border: none;
      color:$placeholder;

      &[type="text"]{
        width:161px;
        background-position: 0 0;
        text-indent:10px;
      }
      &[type="password"]{
        width:161px;
        background-position: 0 0;
        text-indent:10px;
      }
      &[type="submit"] {
        width:60px;
        background-position: -161px 0;
        color:#FFF;
      }
    }
  }
}

.pad-main-bg {
  margin:0;
  background:orange;
  padding-right:10px;
}




@import "left-nav";
@import "news";
@import "faq";
@import "slider";
@import "contact";
@import "links";
@import "feedback";


@import "treatments";

// Small devices (landscape phones, 576px and up)
@media (min-width: 1px) { 
  .homepage-image-title {
    position: absolute;
    height: 50px;
    line-height: 50px;
    vertical-align: middle;
    font-size: 26px;
    font-weight: 400;
    width: 100%;
    margin-top: -50px;
    padding-bottom: 0;
    background:rgba(0,0,0,0.4);
  }

  .show-mobile {
    display: block;
  }

  .hide-mobile {
    display: none;
  }

  #slider {
    display:none;
  }

  header {
    .banner {
      background: url(/images/hessian.jpg) top left no-repeat;
      background-size: cover;
      overflow: auto;
      height: auto;
    }
  }

  .logo-contact {
    background: none;
    height:auto;
  }

  .homepage-articles {
    display: none;
  }

  footer {
    nav {
      width: 100%;
      text-align: center;
      margin: 0 auto;

      ul {
        width: 100%;
        &:last-child {
          float: none;
        }
        li {
          display:inline;
          margin:0 5px;
        }
      }
    }

    .map {
      display: block;
      position: relative;
      width:70%;
      background-color:$footer-secondary-color;
      margin: 0 auto;
      iframe {
        height: 30vh;
        margin:5px 5px 0 5px;
        width: calc(100% - 10px)
      }
    }

    .online-order-form-link {
      width:300px !important;
      margin: 10px auto;
    }
  }

  .homepage-button {
    div {
      margin:0 auto;
    }
  }

  .mobile-search {
    display:block;
    position: relative;
    height: 40px;
    margin:10px;

    form {
      input {
        display:block;
        height:32px;
        float:left;
        background: url(/images/search-box.png) top left no-repeat;
        background-size: 700px 32px;
        border: none;

        &#query {
          width:calc(100% - 62px);
          background-position: 0 0;
          text-indent:10px;
        }
        &[type="submit"] {
          width:62px;
          background-position: -638px 0;
          color:#FFF;
        }
      }
    }
  }
  
  .footer-spacer-for-social-icons {
    display: block;
    height: 80px;
  }

  .bottom {
    display:block;
    width: 100vw;
    height: 55px;
    z-index:50;
    position: absolute;
    margin-top: -155px;
    background-color: $nav-menubar-background;

    ul.social-icons {
      position: relative;
    }

    .download-brochure {
      position: relative;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
  .homepage-image-title {
    position:relative;
    background: none;
    height:auto;
    line-height: auto;
    margin-top: 0;
    padding-bottom: 0;
  }

  .hide-mobile {
    display: block;
  }

  .show-mobile {
    display:none;
  }

  #slider {
    display:block;
  }

  .mobile-search {
    display: none;
  }

  header {
    .banner { 
      background:none;
      height:$banner-height;
    }
  }

  .logo-contact {
    position:absolute;
    top:5px;
    left:0;
    z-index: 2;
    width:350px;
    background: url(/images/header-material.png) top left no-repeat;
    height:$banner-height - 5;
  }

  .homepage-articles {
    padding-top:20px;
    background: $background-color;
    display: flex;
  }
  
  footer {
    nav { 
      display: block; 
      ul {
        width: 50%;
        display: block;
        float: left;

        &:last-child {
          float: right;
        }

        li {
          text-align: left;
          display:block;
        }
      }
    }
    
    .map {
      display: block;
      position: relative;
      width:237px;
      margin:0 auto;
      background-color:$footer-secondary-color;
      iframe {
        margin:5px 5px 0 5px;
        height: 180px;
      }
    }

    .online-order-form-link {
      width:300px !important;
      margin: 0;
    }
  }

  .bottom, .footer-spacer-for-social-icons {
    display:none;
  }
  
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 

}