.margin-10 { margin:10px; }
.margin-20 { margin:20px; }
.margin-sides-50 { margin:0 50px; }
.margin-top-20 { margin-top:20px; }
.margin-bottom-20 { margin-bottom:20px; }
.margin-top-30 { margin-top:30px; }


.white, .red, .blue {
  color:white;
  a {
    color:inherit;
  }
}

.red {
  color:$placeholder;
}

.blue {
  color:$nav-menu-background;
}

section.product, section.article {
  padding-top:20px;
}

.container.overflow {
  overflow: visible;
}

// Medium devices (tablets, 768px and up)
@media (min-width: 1px) { 
  section.article {
    margin:0 10px;
  }
}
// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
  section.article {
    margin:0 65px;
  }
}