section.products {
  display: block;
  position: relative;
  margin-bottom:50px;


  .product {
    display: block;
    position:relative;
    border-bottom:1px solid $placeholder;
    padding-bottom:5px;
    margin:15px 80px 0 0;

    .name {
      display: block;
      position:relative;
      font-size: $article-title-font-size;
      font-weight: 400;
      color:$primary-font-color;
      margin-bottom:10px
    }
    .detail {
      display: block;
      position:relative;

      .image {
        display: inline-block;
        float:left;
        width: 110px;
        height: 110px;
        margin-right:20px;

        img {
          width:100%;
        }
      }

      p {
        color:$primary-font-color;
        font-size:$article-font-size;
        &.suitability {
          margin-bottom:20px;
        }
      }
    }
  }
}

section.product {
  h1.title {
    margin-top:30px;
  }

  h2.subtitle {
    margin-bottom:40px;
  }

  p, li {
    font-size:$article-font-size;
    margin-top:0;
    margin-bottom:0;
  }

  .description, .benefits {
    display: block;
    position: relative;
    margin-bottom:40px;
  }

  .further-info {
    margin-bottom:50px;

    p {
      margin-bottom:0;
      font-size:$article-small-font-size;
    }
  }
}

section.product-additional-detail {
  .image {
    display: block;
    position: relative;
    padding-right:20px;
    margin-top:20px;
    img { width:100%; }
  }

  ul.links {
    list-style:none;
    margin:20px 0 0 0;
    padding:0;
    li {
      margin:10px 0;
      a {
        color:$main-color;
        text-decoration: none;
      }
    }
  }
}


//  Added Rounded Corner Tables
.table-curved {
  border-collapse: separate;
  border: solid $table-border-color 1px;
  border-radius: $table-radius;
  border-left:0px;

  td, th {
    border-left: 1px solid $table-border-color;
    border-top: 1px solid $table-border-color;
  }

  th {
    border-top: none;
  }

  th:first-child {
    border-radius: $table-radius 0 0 0;
  }

  th:last-child {
    border-radius: 0 $table-radius 0 0;
  }

  th:only-child{
    border-radius: $table-radius $table-radius 0 0;
  }

  tr:last-child td:first-child {
    border-radius: 0 0 0 $table-radius;
  }

  tr:last-child td:last-child {
    border-radius: 0 0 $table-radius 0;
  }
}


table.order-table {
  thead {

    border: none !important;
    margin-bottom: 0 !important;
    text-align: center;
    th {
      text-align: center;
      border: none !important;
    }
  }

  tbody {
    border:1px solid #DDD;

    input, select {
      width:100%;
      margin:0;
    }
  }

}

table.order-table {
  thead {
    tr, th {
      border:none !important;
    }
  }

  tbody {
    background-color:white;
  }

}


// Medium devices (tablets, 768px and up)
@media (min-width: 1px) { 
  section.products {
    margin-top:10px;
    .product.clearfix {
      margin: 0 10px;
    }
    background:white;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .section.products {
    margin-top:0; 
    .product {
      margin:15px 80px 0 0;
    }
  }  
}