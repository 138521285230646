section.faq {
  margin:20px 40px 50px 40px;

  h2, h3 {
    color: $placeholder;
    margin:10px 0;
  }

  h3 {
    margin-bottom:20px;
  }

  article {
    border-bottom:1px solid $placeholder;
    padding-bottom:20px;
    margin-left:50px;
    margin-top:20px;

    .question {
      font-size: $article-title-font-size;
      font-weight: 400;

      &::before {
        display: inline-block;
        width:30px;
        margin-left:-30px;
        content: 'Q:';
        font-size: 25px;
        font-weight: 600;
        color:white;
        text-shadow: 0px 0px 2px rgba(150, 150, 150, 1);
      }
    }

    .answer {
      font-size: $article-font-size;
      font-weight: 300;

      &::before {
        display: inline-block;
        width:30px;
        margin-left:-30px;
        content: 'A:';
        font-size: 25px;
        font-weight: 600;
        color:white;
        text-shadow: 0px 0px 2px rgba(150, 150, 150, 1);
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 1px) { 
  section.faq {
    margin: 20px 20px 50px 20px;
  }
}
// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
  section.faq {
    margin: 20px 40px 50px 40px;
  }
}
