h2 {
  font-size:26px;
  font-weight:400;
  margin-top:10px;
  color:$main-color;
}

h3 {
  font-size:16px;
  font-weight:400;
}

p {
  font-size:14px;
  line-height: 1.5em;
}

.center {
  text-align: center;
}