.news-listing {
  ul {
    list-style: none;
    padding:0;
    margin:0px;

    li {
      margin:0 0 10px 0;
      a {
        color:$nav-menu-background;
        text-decoration: none;
        font-weight:400;
      }
    }
  }
}

.article-margin {
  margin:20px 50px 50px 50px;
}


section.news-articles {

  margin-bottom:50px;

  article {
    border-bottom:1px solid $placeholder;
    padding-bottom:10px;
    margin-left:20px;
    margin-right:20px;

    &.prime {
      header {
        background-size: cover;
        height:auto;
        margin-left:-34px;
        margin-right:-70px;
        h1 {
          display: block;
          width: 360px;
          margin-left:30px;
          padding:20px 80px 20px 20px;
          background-color: white;
          color:$primary-font-color;
        }
      }
    }

    h1, h2, p {
      font-weight:300;
      color:$primary-font-color;
    }

    h1, h2 {
      margin:20px 0;
    }

    p { font-size: $article-small-font-size; }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 1px) { 
  article {
    header {
      background: none;
    }
  }
}
// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
  article {
    .header > img {
      display: block;
    }
  }
}