
textarea#address {
  height:106px;
}

// Medium devices (tablets, 768px and up)
@media (min-width: 1px) { 
  .contact-form-wrapper {
    margin:20px 10px 70px 10px;
  }
}
// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
  .contact-form-wrapper {
    margin:20px 100px 70px 100px;
  }  
}